/*
 * 目前使用trtc版本为4.13版本
 */
import TRTC from 'trtc-js-sdk';
import {
	isUndefined
} from '@/utils/utils.js';

export default {
	data() {
		return {
			client: null,
			localStream: null,
			remoteStreamList: [],
			isJoining: false,
			isJoined: false,
			isPublishing: false,
			isPublished: false,
			isMutedVideo: false,
			isMutedAudio: false,
			isPlayingLocalStream: false,
			cameras: [], //摄像头设备列表
			cameras_deviceId: '',
			andr: 0, //安卓设备默认选中摄像头索引
			camm_index: 0,
			is_android: 0, //是否安卓平台 0否  1是
			first_join_room:1,//是否为初次进入直播间  1是 其他否
			timel:null,//倒计时
		};
	},
	methods: {
		// 初始化客户端
		async initClient() {
			this.client = TRTC.createClient({
				mode: 'live',
				sdkAppId: this.sdkAppId,
				userId: this.userId,
				userSig: this.userSig,
				enableAutoPlayDialog: false,
			});
			this.addSuccessLog(`Client [${this.userId}] created.`);
			this.handleClientEvents();
		},
		async initLocalStream() {
			
			this.localStream = TRTC.createStream({
				audio: true,
				video: true,
				userId: this.userId,
				cameraId: this.cameraId,
				microphoneId: this.microphoneId,
			});
			try {
				await this.localStream.initialize();
				
				if(sessionStorage.getItem('platform')){
					await this.defaultNoAudio()//默认静音
				}
				this.addSuccessLog(`LocalStream [${this.userId}] initialized.`);
			} catch (error) {
				this.localStream = null;
				this.addFailedLog(`LocalStream failed to initialize. Error: ${error.message}.`);
				throw error;
			}
		},
		playLocalStream() {
			this.localStream.play('localStream')
				.then(() => {
					this.isPlayingLocalStream = true;
					this.addSuccessLog(`LocalStream [${this.userId}] playing.`);
				})
				.catch((error) => {
					this.addFailedLog(`LocalStream [${this.userId}] failed to play. Error: ${error.message}`);
				});
		},
		destroyLocalStream() {
			this.localStream && this.localStream.stop();
			this.localStream && this.localStream.close();
			this.localStream = null;
			this.isPlayingLocalStream = false;
		},
		playRemoteStream(remoteStream, element) {
			if (remoteStream.getType() === 'main' && remoteStream.getUserId().indexOf('share') >= 0) {
				remoteStream.play(element, {
					objectFit: 'contain'
				}).catch();
			} else {
				remoteStream.play(element).catch();
			}
		},
		resumeStream(stream) {
			stream.resume();
		},
		// 主播进入
		async joinanchor() {
			if (this.isJoining || this.isJoined) {
				return;
			}
			this.isJoining = true;
			!this.client && await this.initClient();
			try {
				await this.client.join({
					roomId: this.roomId,
					role: 'anchor'
				});
				this.isJoining = false;
				this.isJoined = true;

				this.addSuccessLog(`Join room [${this.roomId}] success.`);
				this.reportSuccessEvent('joinRoom');

				this.startGetAudioLevel();
			} catch (error) {
				this.isJoining = false;
				console.error('join room failed', error);
				this.addFailedLog(
					`Join room ${this.roomId} failed, please check your params. Error: ${error.message}`);
				this.reportFailedEvent('joinRoom', error);
				throw error;
			}
		},
		// 观众进入
		async join() {
			if (this.isJoining || this.isJoined) {
				return;
			}
			this.isJoining = true;
			!this.client && await this.initClient();
			try {
				await this.client.join({
					roomId: this.roomId
				});
				this.isJoining = false;
				this.isJoined = true;

				this.addSuccessLog(`Join room [${this.roomId}] success.`);
				this.reportSuccessEvent('joinRoom');

				this.startGetAudioLevel();
			} catch (error) {
				this.isJoining = false;
				console.error('join room failed', error);
				this.addFailedLog(
					`Join room ${this.roomId} failed, please check your params. Error: ${error.message}`);
				this.reportFailedEvent('joinRoom', error);
				throw error;
			}
		},

		async publish() {
			if (!this.isJoined || this.isPublishing || this.isPublished) {
				return;
			}
			this.isPublishing = true;
			try {
				await this.client.publish(this.localStream);
				this.isPublishing = false;
				this.isPublished = true;

				this.addSuccessLog('LocalStream is published successfully.');
				this.reportSuccessEvent('publish');
			} catch (error) {
				this.isPublishing = false;
				console.error('publish localStream failed', error);
				this.addFailedLog(`LocalStream is failed to publish. Error: ${error.message}`);
				this.reportFailedEvent('publish');
				throw error;
			}
		},

		async unPublish() {
			if (!this.isPublished || this.isUnPublishing) {
				return;
			}
			this.isUnPublishing = true;
			try {
				await this.client.unpublish(this.localStream);
				this.isUnPublishing = false;
				this.isPublished = false;

				this.addSuccessLog('localStream unpublish successfully.');
				this.reportSuccessEvent('unpublish');
			} catch (error) {
				this.isUnPublishing = false;
				console.error('unpublish localStream failed', error);
				this.addFailedLog(`LocalStream is failed to unpublish. Error: ${error.message}`);
				this.reportFailedEvent('unpublish', error);
				throw error;
			}
		},
		async subscribe(remoteStream, config = {
			audio: true,
			video: true
		}) {
			try {
				await this.client.subscribe(remoteStream, {
					audio: isUndefined(config.audio) ? true : config.audio,
					video: isUndefined(config.video) ? true : config.video,
				});
				this.addSuccessLog(`Subscribe [${remoteStream.getUserId()}] success.`);
				this.reportSuccessEvent('subscribe');
			} catch (error) {
				console.error(
					`subscribe ${remoteStream.getUserId()} with audio: ${config.audio} video: ${config.video} error`,
					error);
				this.addFailedLog(`Subscribe ${remoteStream.getUserId()} failed!`);
				this.reportFailedEvent('subscribe', error);
			}
		},
		async unSubscribe(remoteStream) {
			try {
				await this.client.unsubscribe(remoteStream);
				this.addSuccessLog(`unsubscribe [${remoteStream.getUserId()}] success.`);
				this.reportSuccessEvent('unsubscribe');
			} catch (error) {
				console.error(`unsubscribe ${remoteStream.getUserId()} error`, error);
				this.addFailedLog(`unsubscribe ${remoteStream.getUserId()} failed!`);
				this.reportFailedEvent('unsubscribe', error);
			}
		},
		async leave() {
			if (!this.isJoined || this.isLeaving) {
				return;
			}
			this.isLeaving = true;
			this.stopGetAudioLevel();
			this.isPublished && await this.unPublish();
			this.localStream && this.destroyLocalStream();

			try {
				await this.client.leave();
				this.isLeaving = false;
				this.isJoined = false;

				this.addSuccessLog('Leave room success.');
				this.reportSuccessEvent('leaveRoom');
			} catch (error) {
				this.isLeaving = false;
				console.error('leave room error', error);
				this.addFailedLog(`Leave room failed. Error: ${error.message}`);
				this.reportFailedEvent('leaveRoom', error);
				throw error;
			}
		},
		muteVideo() {
			if (this.localStream) {
				this.localStream.muteVideo();
				this.isMutedVideo = true;
				this.addSuccessLog('LocalStream muted video.');
			}
		},
		muteAudio() {
			if (this.localStream) {
				this.localStream.muteAudio();
				this.isMutedAudio = true;
				this.addSuccessLog('LocalStream muted audio.');
			}
		},
		unmuteVideo() {
			if (this.localStream) {
				this.localStream.unmuteVideo();
				this.isMutedVideo = false;
				this.addSuccessLog('LocalStream unmuted video.');
			}
		},
		unmuteAudio() {
			if (this.localStream) {
				this.localStream.unmuteAudio();
				this.isMutedAudio = false;
				this.addSuccessLog('LocalStream unmuted audio.');
			}
		},
		switchDevice(type, deviceId) {
			try {
				if (this.localStream) {
					this.localStream.switchDevice(type, deviceId);
					this.addSuccessLog(`Switch ${type} device success.`);
				}
			} catch (error) {
				// console.error('switchDevice failed', error);
				this.addFailedLog(`Switch ${type} device failed.`);
			}
		},
		startGetAudioLevel() {
			// 文档：https://web.sdk.qcloud.com/trtc/webrtc/doc/zh-cn/module-ClientEvent.html#.AUDIO_VOLUME
			this.client.on('audio-volume', (event) => {
				event.result.forEach(({
					userId,
					audioVolume
				}) => {
					if (audioVolume > 2) {
						console.log(`user: ${userId} is speaking, audioVolume: ${audioVolume}`);
					}
				});
			});
			this.client.enableAudioVolumeEvaluation(200);
		},
		stopGetAudioLevel() {
			this.client && this.client.enableAudioVolumeEvaluation(-1);
		},
		handleClientEvents() {
			var $this = this
			this.client.on('error', (error) => {
				console.error('error');
				console.error(error);
				console.error('error');
				// alert(error)
				if (error.indexOf('INVALID_PARAMETER') >= 0) {
					alert('无效参数')
				} else if (error.indexOf('INVALID_OPERATION') >= 0) {
					alert('非法操作')
				} else if (error.indexOf('NOT_SUPPORTED') >= 0) {
					alert('当前设备不支持')
				} else if (error.indexOf('DEVICE_NOT_FOUND') >= 0) {
					alert('当前设备没有麦克风或没有摄像头，但尝试采集麦克风、摄像头')
				} else if (error.indexOf('INITIALIZE_FAILED') >= 0) {
					alert('页面初始化失败，请重新进入直播间')
				} else if (error.indexOf('SIGNAL_CHANNEL_SETUP_FAILED') >= 0) {
					alert('业务错误，请重新进入直播间')
				} else if (error.indexOf('SIGNAL_CHANNEL_ERROR') >= 0) {
					alert('WebSocket 信令通道错误')
				} else if (error.indexOf('ICE_TRANSPORT_ERROR') >= 0) {
					alert('连接错误')
				} else if (error.indexOf('JOIN_ROOM_FAILED') >= 0) {
					alert('服务端错误，请重新进入直播间')
				} else if (error.indexOf('CREATE_OFFER_FAILED') >= 0) {
					alert('创建房间失败')
				} else if (error.indexOf('SIGNAL_CHANNEL_RECONNECTION_FAILED') >= 0) {
					alert('WebSocket 信令通道重连失败')
				} else if (error.indexOf('UPLINK_RECONNECTION_FAILED') >= 0) {
					alert('重连失败')
				} else if (error.indexOf('DOWNLINK_RECONNECTION_FAILED') >= 0) {
					alert('重连失败')
				} else if (error.indexOf('REMOTE_STREAM_NOT_EXIST') >= 0) {
					alert('远端流不存在')
				} else if (error.indexOf('CLIENT_BANNED') >= 0) {
					alert('用户使用其他设备进入直播间或被踢出房间')
				} else if (error.indexOf('SERVER_TIMEOUT') >= 0) {
					alert('媒体传输服务超时')
				} else if (error.indexOf('SUBSCRIPTION_TIMEOUT') >= 0) {
					alert('远端流订阅超时')
				} else if (error.indexOf('PLAY_NOT_ALLOWED') >= 0) {
					alert('自动播放被禁止错误')
				} else if (error.indexOf('DEVICE_AUTO_RECOVER_FAILED') >= 0) {
					alert('自动恢复摄像头、麦克风采集失败')
				} else if (error.indexOf('START_PUBLISH_CDN_FAILED') >= 0) {
					alert('推流失败')
				} else if (error.indexOf('STOP_PUBLISH_CDN_FAILED') >= 0) {
					alert('推流失败')
				} else if (error.indexOf('START_MIX_TRANSCODE_FAILED') >= 0) {
					alert('开始混流转码失败')
				} else if (error.indexOf('STOP_MIX_TRANSCODE_FAILED') >= 0) {
					alert('停止混流转码失败')
				} else if (error.indexOf('NOT_SUPPORTED_H264') >= 0) {
					alert('当前设备不支持')
				} else if (error.indexOf('SWITCH_ROLE_FAILED') >= 0) {
					alert('切换角色失败')
				} else if (error.indexOf('API_CALL_TIMEOUT') >= 0) {
					alert('连接超时')
				} else if (error.indexOf('SCHEDULE_FAILED') >= 0) {
					alert('信令调度失败')
				} else if (error.indexOf('UNKNOWN') >= 0) {
					alert('未知错误')
				} else {
					alert('未知错误！')
				}

			});
			this.client.on('client-banned', async (error) => {
				console.error(`client has been banned for ${error}`);

				this.isPublished = false;
				this.localStream = null;
				await this.leave();
				// alert(error);
				// if(sessionStorage.getItem('platform')){
				// 	is_android:0,//是否安卓平台 0否  1是
				// 	this.is_android=1
				// }
				if (sessionStorage.getItem('platform')) {
					this.$dialog.confirm({
							title: '温馨提示',
							message: '已退出直播间',
							showCancelButton: false
						})
						.then(() => {
							// on confirm
							wx.miniProgram.navigateBack({
								delta: 1,
								fail() {
									wx.miniProgram.reLaunch({
										url: '/pages/mymodular/order/order',
										success: (res) => {},
										fail: (res) => {},
										complete: (res) => {},
									})
								}
							})
							// wx.miniProgram.reLaunch
						})
						.catch(() => {
							// on cancel
						});
				} else {
					$this.backCoachClientList()
					// this.$dialog.confirm({
					// 	title: '温馨提示',
					// 	message: '用户使用其他设备进入直播间或被踢出房间',
					// 	showCancelButton: false
					// })
					// .then(() => {
					// 	// on confirm
					// 	console.log($this.backCoachClientList())
					// })
					// .catch(() => {
					// 	// on cancel
					// });
				}
			});
			// fired when a remote peer is joining the room
			this.client.on('peer-join', (event) => {
				const {
					userId
				} = event;
				console.log(`peer-join ${userId}`, event);
			});
			// fired when a remote peer is leaving the room
			this.client.on('peer-leave', (event) => {
				const {
					userId
				} = event;
				console.log(`peer-leave ${userId}`, event);
			});

			// fired when a remote stream is added
			this.client.on('stream-added', (event) => {
				const {
					stream: remoteStream
				} = event;
				const remoteUserId = remoteStream.getUserId();
				if (remoteUserId === `share_${this.userId}`) {
					// don't need screen shared by us
					this.unSubscribe(remoteStream);
				} else {
					console.log(`remote stream added: [${remoteUserId}] type: ${remoteStream.getType()}`);
					// subscribe to this remote stream
					this.subscribe(remoteStream);
					this.addSuccessLog(`RemoteStream added: [${remoteUserId}].`);
				}
			});
			// fired when a remote stream has been subscribed
			this.client.on('stream-subscribed', (event) => {
				
				const {
					stream: remoteStream
				} = event;
				const remoteUserId = remoteStream.getUserId();
				console.log('stream-subscribed userId: ', remoteUserId);
				this.addSuccessLog(`RemoteStream subscribed: [${remoteUserId}].`);
				this.remoteStreamList.push(remoteStream);
				this.$nextTick(() => {
					this.playRemoteStream(remoteStream, remoteUserId);
				});
			});
			// fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
			this.client.on('stream-removed', (event) => {
				const {
					stream: remoteStream
				} = event;
				remoteStream.stop();
				const index = this.remoteStreamList.indexOf(remoteStream);
				if (index >= 0) {
					this.remoteStreamList.splice(index, 1);
				}
				// console.log(`stream-removed userId: ${remoteStream.getUserId()} type: ${remoteStream.getType()}`);
			});

			this.client.on('stream-updated', (event) => {
				const {
					stream: remoteStream
				} = event;
				// console.log(`type: ${remoteStream.getType()} stream-updated hasAudio: ${remoteStream.hasAudio()} hasVideo: ${remoteStream.hasVideo()}`);
				this.addSuccessLog(
					`RemoteStream updated: [${remoteStream.getUserId()}] audio:${remoteStream.hasAudio()}, video:${remoteStream.hasVideo()}.`
					);
			});

			this.client.on('mute-audio', (event) => {
				const {
					userId
				} = event;
				// console.log(`${userId} mute audio`);
				this.addSuccessLog(`[${event.userId}] mute audio.`);
			});
			this.client.on('unmute-audio', (event) => {
				const {
					userId
				} = event;
				// console.log(`${userId} unmute audio`);
				this.addSuccessLog(`[${event.userId}] unmute audio.`);
			});
			this.client.on('mute-video', (event) => {
				const {
					userId
				} = event;
				// console.log(`${userId} mute video`);
				this.addSuccessLog(`[${event.userId}] mute video.`);
			});
			this.client.on('unmute-video', (event) => {
				const {
					userId
				} = event;
				// console.log(`${userId} unmute video`);
				this.addSuccessLog(`[${event.userId}] unmute video.`);
			});

			this.client.on('connection-state-changed', (event) => {
				console.log(`RtcClient state changed to ${event.state} from ${event.prevState}`);
			});
			this.client.on('network-quality', (event) => {
				const {
					uplinkNetworkQuality,
					downlinkNetworkQuality
				} = event;
				// console.log(`network-quality uplinkNetworkQuality: ${uplinkNetworkQuality}, downlinkNetworkQuality: ${downlinkNetworkQuality}`);
			});
			TRTC.getCameras().then(devices => {
				$this.cameras = devices;
				devices.forEach(dev => {
					// console.log('camera label: ' + dev.label + ' deviceId: ' + dev.deviceId);
				});
				// this.initSwitchDevice('video',this.cameras[0].deviceId)
			});
			
		},
		defaultNoAudio(){
			var $this = this
			console.log('defaultNoAudio')
			setTimeout(function(){
				console.log('this.timel')
				if ($this.localStream && $this.first_join_room==1) {
					$this.localStream.muteAudio();
					$this.isMutedAudio = true;
					$this.first_join_room=0
				}else{
					$this.defaultNoAudio()
				}
			}, 1000)
		},
		switchCamera(e) {
			var $this = this
			TRTC.getCameras().then(devices => {
				$this.camm_index = e
				console.log('点击了切换摄像头')
				console.log(e)
				console.log(devices)
				console.log(devices[e].deviceId)
				console.log('点击了切换摄像头')
				let cameraId = devices[e].deviceId
				$this.localStream.switchDevice('video', cameraId).then(() => {
					console.log('switch camera success');
				});
			});
			// this.camm_index=e
			// console.log('点击了切换摄像头')
			// console.log(typeof(this.cameras))
			// console.log(this.cameras)
			// console.log(e)
			// console.log('点击了切换摄像头')
			// let cameraId = this.cameras[e].deviceId;
			// this.localStream.switchDevice('video', cameraId).then(() => {
			//   console.log('switch camera success');
			// });
		},
		switchCamera111() {
			console.log('切换摄像头')
			console.log(this.cameras)
			// cameras_cameraId

			let cameraId = ''
			var u = navigator.userAgent;
			var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

			if (this.cameras_deviceId == '') {
				cameraId = this.cameras[0].deviceId;
				this.cameras_deviceId == this.cameras[0].deviceId
			} else {
				if (this.cameras_deviceId == this.cameras[0].deviceId) {
					cameraId = this.cameras[1].deviceId
					this.cameras_deviceId = this.cameras[1].deviceId
				} else {
					cameraId = this.cameras[0].deviceId
					this.cameras_deviceId = this.cameras[0].deviceId
				}
			}
			console.log('cameraId=' + cameraId + ',cameras_deviceId=' + this.cameras_deviceId)
			this.localStream.switchDevice('video', cameraId).then(() => {
				console.log('switch camera success');
			});
			console.log('切换摄像头')
		},
		// 返回教练列表端
		backCoachClientList(){
			var $this=this
			console.log(window.location.href)
			let url=window.location.href
			// http://coach.yogain.cn/index/login/login
			// https://test.coach.yogain.cn/
			if(url.indexOf('192')>=0 ||url.indexOf('https://test')>=0){
				window.location.href='https://test.coach.yogain.cn'
			}else{
				window.location.href='http://coach.yogain.cn'
			}
		},
	},
};
