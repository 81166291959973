<!--
 * @Description: quick demo - vue2 版本页面
 * @Date: 2022-03-14 16:56:36
 * @LastEditTime: 2022-03-29 17:01:32
-->
<template>
  <div id="app">
    <!-- 头部栏 -->
    <!-- <comp-nav></comp-nav> -->
    <div class="content-mobile_border" :class="$isMobile && 'content-mobile'">
      <!-- quick demo 使用指引 -->
      <!-- <comp-guidance></comp-guidance> -->
      <!-- sdkAppId、secretKey、userId、roomId 参数输入区域 -->
      <!-- <p class="label">{{ $t('Params') }}</p> -->
      <!-- <div class="param-container" :class="$isMobile && 'param-container-mobile'"> -->
        <!-- <comp-info-input
          label="sdkAppId" type="number" @change="handleValueChange($event, 'sdkAppId')"></comp-info-input>
        <comp-info-input
          label="secretKey" @change="handleValueChange($event, 'secretKey')"></comp-info-input> -->
      <!-- </div> -->
      <!-- <div class='alert'>
        <el-alert type="error" :closable="false" >
          <span>{{ $t("Alert")}} <a target="_blank" :href="$t('Url')">{{ $t("Click")}}</a></span>
        </el-alert>
      </div> -->
      <!-- 设备选择区域 -->
      <!-- <p class="label">{{ $t('Device Select') }}</p>
      <div class="param-container" :class="$isMobile && 'param-container-mobile'">
        <comp-device-select
          deviceType="camera" @change="handleValueChange($event, 'cameraId')"></comp-device-select>
        <comp-device-select
          deviceType="microphone" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>
      </div> -->
      <!-- rtc 房间 -->
      <comp-room
        :sdkAppId="Number(sdkAppId)"
        :secretKey="secretKey"
        :userId="userId"
        :roomId="Number(roomId)"
        :cameraId="cameraId"
        :microphoneId="microphoneId"
		
		:day="day"
		:scheduleId="schedule_id"
		:courses_type="courses_type"
		:coach_name='coach_name'
		:ts_type="ts_type"
		></comp-room>
		<!-- 
		
		
		:courses_type="courses_type"
		:scheduleId="schedule_id"
		:user_id_short="user_id_short"
		:day="day"
		
		
		 userId: '',
		 roomId: 0,
		 cameraId: '',
		 microphoneId: '',
		 userSig:'',
		 courses_type:'',
		 schedule_id:'',
		 day:'',
		 sd_course_name:''
		 -->
		<!-- <div>
			<comp-info-input label="userId" @change="handleValueChange($event, 'userId')"></comp-info-input>
			<comp-info-input label="roomId" type="number" @change="handleValueChange($event, 'roomId')"></comp-info-input>
		</div> -->
		
    </div>
	
	
	<!-- popup -->
	
  </div>
</template>

<script>
// import compNav from '@/components/comp-nav.vue';
// import compGuidance from '@/components/comp-guidance.vue';
import compInfoInput from '@/components/comp-info-input.vue';
// import compDeviceSelect from '@/components/comp-device-select.vue';
import compRoom from '@/components/comp-room2.vue';
import { clearUrlParam,getUrlParam,UrlParamparse } from '@/utils/utils';

export default {
  name: 'App',
  components: {
    // compNav,
    // compGuidance,
    // compInfoInput,
    // compDeviceSelect,
    compRoom,
  },
  data() {
    return {
      // sdkAppId: 1400749772,//1400710237 正式  1400749772 测试
	  sdkAppId: 1400710237,
      secretKey: '5fe44b703b41b5e3784ec8274fe6fa95d0e0d2eed553ffc82d33b3f91f7f2d2c',//'2bcd063f481820b37d67f3a00a9ad46781d1111d7fd38ec112f0b36feab74262',
      userId: '',
      roomId: 0,
      cameraId: '',
      microphoneId: '',
	  userSig:'',
	  courses_type:1,
	  schedule_id:0,
	  day:'',
	  sd_course_name:'',
	  coach_name:'',
	  ts_type:1
    };
  },
  methods: {
    handleValueChange(value, key) {
      this[key] = value;
    },
	
  },
  created() {
  	if((window.location.href).indexOf('yogainlive.cn')>=0){
		this.sdkAppId=1400710237
	}else{
		this.sdkAppId=1400749772
	}
  },
  mounted() {
    // clearUrlParam();
	/**
	 * 
	 * sessionStorage.setItem('',to.query.coach_live_roome)
	sessionStorage.setItem('live_room_param',to.query.live_room_param)
	*/ 
	let coach_live_roome_str=sessionStorage.getItem('coach_live_roome')
	// getUrlParam('coach_live_roome')
	let live_room_param_str=sessionStorage.getItem('live_room_param')
	// getUrlParam('live_room_param')
	console.log('live_room_param_str')
	console.log(coach_live_roome_str)
	console.log(live_room_param_str)
	console.log('live_room_param_str')
	
	
	let coach_live_roome=UrlParamparse(coach_live_roome_str)
	let live_room_param=UrlParamparse(live_room_param_str)
	
	console.log(coach_live_roome)
	console.log(live_room_param)
	console.log('----页面参数----');
	console.log(coach_live_roome)
	console.log(live_room_param)
	
	
	// console.log(this.$route);
	// console.log(this.$route.query);
	
	console.log('----页面参数----');
	// return
	// this.sdkAppId = Number(getUrlParam('sdkAppId'));//sdkAppId固定在前端，不带在参数中
	this.userSig = live_room_param.user_sign//getUrlParam('userSig');
	this.userId =live_room_param.video_uid// getUrlParam('userId');
	this.roomId =live_room_param.room_num// Number(getUrlParam('roomId'));
	this.courses_type=Number(coach_live_roome.type)//getUrlParam('courses_type');
	this.schedule_id=Number(coach_live_roome.schedule_id)//getUrlParam('schedule_id');
	this.coach_name=coach_live_roome.coach_name
	// this.user_id_short=getUrlParam('user_id_short');
	this.day=coach_live_roome.day//getUrlParam('day');
	this.ts_type=Number(coach_live_roome.type)
	// clearUrlParam();
	this.sd_course_name=sessionStorage.getItem('sd_course_name');
	// if(getUrlParam('sd_course_name')){
	// 	document.title=getUrlParam('sd_course_name')
	// }
	
  },
};
</script>

<style lang="scss" scoped>
	$vm_base:1/750*100vw;
	.content-mobile_border{
		width: 100%;
		height: 100vh;
		overflow: hidden;
		// overflow-y: auto;
		background-color: rgba(0, 0, 0, 0.7);
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  .content {
    width: 80%;
    margin: 0 auto;
    max-width: 1320px;
    .alert {
      padding-top: 20px;
      font-size: 16px !important;
    }
    &.content-mobile {
      width: 100%;
      padding: 0 16px 20px;
    }
    .label {
      margin: 14px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          // margin-bottom: 10px;
        }
      }
    }
  }
}

</style>

<i18n>
{
	"en": {
		"Params": "Params",
    "Device Select": "Device Select",
    "Alert": "Notes: this Demo is only applicable for debugging. Before official launch, please migrate the UserSig calculation code and key to your backend server to avoid unauthorized traffic use caused by the leakage of encryption key.",
    "Click": "View documents",
    "Url": "https://intl.cloud.tencent.com/document/product/647/35166"
	},
	"zh": {
		"Params": "参数",
    "Device Select": "设备选择",
    "Alert": "注意️：本 Demo 仅用于调试，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。",
    "Click": "查看文档",
    "Url": "https://cloud.tencent.com/document/product/647/17275"
	}
}
</i18n>

