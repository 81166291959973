import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/invite-:code',
		name: 'Invite',
		component: () => import( '../views/Invite.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});
router.beforeEach((to, from, next) => {
	console.log(to)
	// return
	if(to.query == {}){
		if(sessionStorage.getItem('query')){
			console.log(sessionStorage.getItem('query'))
			let query=JSON.parse(sessionStorage.getItem('query'))
			console.log(query)
		}
	}else{
		sessionStorage.setItem('query',to.query)
		// 
		if(to.query.coach_live_roome){
			sessionStorage.setItem('coach_live_roome',to.query.coach_live_roome)
		}
		if(to.query.live_room_param){
			sessionStorage.setItem('live_room_param',to.query.live_room_param)
		}
		if(to.query.platform){
			sessionStorage.setItem('platform',1)
		}
		if (to.query.sd_course_name) {
			if((to.query.sd_course_name).indexOf('线上')>=0){
				document.title = to.query.sd_course_name
			}else{
				document.title = '线上 · '+to.query.sd_course_name
			}
		}
		if(to.query.userSig){
			sessionStorage.setItem('userSig',to.query.userSig)
		}
		if(to.query.userId){
			sessionStorage.setItem('userId',to.query.userId)
		}
		if(to.query.roomId){
			sessionStorage.setItem('roomId',to.query.roomId)
		}
		if(to.query.courses_type){
			sessionStorage.setItem('courses_type',to.query.courses_type)
		}
		if(to.query.schedule_id){
			sessionStorage.setItem('schedule_id',to.query.schedule_id)
		}
		if(to.query.user_id_short){
			sessionStorage.setItem('user_id_short',to.query.user_id_short)
		}
		if(to.query.mobile){
			sessionStorage.setItem('mobile',to.query.mobile)
		}
		if(to.query.day){
			sessionStorage.setItem('day',to.query.day)
		}
		if(to.query.sd_course_name){
			sessionStorage.setItem('sd_course_name',to.query.sd_course_name)
		}
	}
	
	next()
});

export default router;
