/*
 * @Description: 通用函数
 * @Date: 2022-03-10 15:17:05
 * @LastEditTime: 2022-03-29 15:20:26
 */

/**
 * 从 window.location.href 中获取指定key的value
 * @param {*} key 要获取的 key
 * @returns window.location.href 中指定key对应的value
 * @example
 * const value = getUrlParam(key);
 */
export function parseQueryString(url) {
  // TODO
  var obj = {};
    var params = url.slice(1);
    var arr = (params.split('&'));
    arr.forEach((v, i) => {/*  */
      let str=v.split('=')[1]; 
      if(str.indexOf('%')===-1){
        obj[v.split("=")[0]] =v.split("=")[1];
      }else{
        obj[v.split("=")[0]] =JSON.parse(decodeURIComponent(v.split("=")[1]));
      }
     
    })
    return obj;
}
export function getUrlParam(key) {
  const url = decodeURI(window.location.href.replace(/^[^?]*\?/, ''));
  const regexp = new RegExp(`(^|&)${key}=([^&#]*)(&|$|)`, 'i');
  const paramMatch = url.match(regexp);

  return paramMatch ? paramMatch[2] : null;
}

export function clearUrlParam() {
  location.href = location.href.slice(0, location.href.indexOf('?') > 0 ? location.href.indexOf('?') : location.href.length);
}
export function UrlParamparse(value) {
	// %3A
	let asfs=value.replace(/%3A/g,':')
	let valueM=JSON.parse(asfs)
  return valueM 
}

export function isUndefined(value) {
  return value === 'undefined';
}

/**
 * 获取语言
 * @returns language
 */
export function getLanguage() {
  let language = localStorage.getItem('trtc-quick-vue2-language') || getUrlParam('lang') || navigator.language || 'zh';
  language = language.replace(/_/, '-').toLowerCase();

  if (language === 'zh-cn' || language === 'zh') {
    language = 'zh';
  } else if (language === 'en' || language === 'en-us' || language === 'en-GB') {
    language = 'en';
  }
  return language;
}
// 计算两个时间戳之间相差的天数
export function getDaysAndHours(data){

  var runTime=parseInt(data / 1000);//总时间[秒]

  // 年
  var year = Math.floor(runTime / 86400 / 365);//向下取整
  // 月
  runTime = runTime % (86400 * 365);//取上一个计算剩余的时间
  var month = Math.floor(runTime / 86400 / 30);
  // 日
  runTime = runTime % (86400 * 30);
  var day = Math.floor(runTime / 86400);
  // 时
  runTime = runTime % 86400;
  var hour = Math.floor(runTime / 3600);
  // 分
  runTime = runTime % 3600;
  var minute = Math.floor(runTime / 60);
  // 秒
  runTime = runTime % 60;
  var second = runTime;

  var params={
    year:year,
    month:month,
    day:day,
    hour:hour,
    minute:minute,
    second:second
  }
　return params
}
/**
 * 当前浏览器是否为移动端浏览器
 */
export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
