/**
 * api 接口
 */

const Lapi = '/fapi' //http://test.api.official.unionfit.cn
const Wapi = '/wxampapi'
const ampapi = '/xcxampapi'
const yogainapi = '/yapi'
const ycoachapi='/ycoachapi'
//
const userApi = {
	getScheduleByOrder: yogainapi + '/api/TenXunYunAudio/getScheduleByOrder', //获取H5直播间信息
	getEventCallBack: yogainapi + '/api/ten_xun_yun_audio/getEventCallBack', // 实时获取用户行为
	dissCoursesRoom: ycoachapi + '/api/ten_xun_yun_audio/diss_room',//解散房间
	getScheduleUrl: yogainapi + '/api/ten_xun_yun_audio/getUrl',
	dissdDissolve: ycoachapi + '/api/ten_xun_yun_audio/dissolve',//踢人
}
export default {
	userApi
}
